

@font-face {
  font-family: "LatoBlack";
  src: local("Lato"), url("./Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBlackItalic";
  src: local("Lato"), url("./Lato-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBold";
  src: local("Lato"), url("./Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBoldItalic";
  src: local("Lato"), url("./Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoItalic";
  src: local("Lato"), url("./Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLight";
  src: local("Lato"), url("./Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLightItalic";
  src: local("Lato"), url("./Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoRegular";
  src: local("Lato"), url("./Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LatoThin";
  src: local("Lato"), url("./Lato-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "LatoThinItalic";
  src: local("Lato"), url("./Lato-ThinItalic.ttf") format("truetype");
}